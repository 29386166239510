<template>
  <div class="h-100 d-flex justify-content-center align-items-center">
    <div class="wrapper rounded text-center p-5">
      <div class="icon">
        <i class="icon-check text-primary"></i>
      </div>
      <div class="info mb-5">
        <p class="fs-2 fw-bold">Correo verificado</p>
        <p class="fs-4 mb-1">Gracias por tu apoyo, hemos verificado tu cuenta.</p>
        <p class="fs-4">Ahora ya puedes proceder a iniciar sesión.</p>
      </div>
      <router-link class="btn btn-primary" to="/login">Continuar </router-link>
    </div>

    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-P4Z6V3X"
        height="0"
        width="0"
        style="display: none; visibility: hidden"></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
export default {
  name: 'EmailVerified',
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 15rem;
}
.wrapper {
  background: #fff;
  box-shadow: 4px 5px 16px -1px rgba($dark, 0.15);
  -webkit-box-shadow: 4px 5px 16px -1px rgba($dark, 0.15);
  -moz-box-shadow: 4px 5px 16px -1px rgba($dark, 0.15);
}
</style>
